import React, { useEffect, useRef, useState } from "react";
import { CirromStyles } from "./components/CirromStyles";
import { theme } from "./theme/theme";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";

import Box from "@mui/material/Box";
import localConfig from "./aws-constants";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { Auth, Storage } from "aws-amplify";
import {
  AppBar,
  Card,
  CardContent,
  Divider,
  Button,
  LinearProgress,
  MenuItem,
  Select,
  Toolbar,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { useSnackbar } from "notistack";
import CardHeader from "@mui/material/CardHeader";
import config from "./aws-exports";

function App() {
  const { enqueueSnackbar } = useSnackbar();

  const inputWidget = useRef(undefined);

  const [selectedRegion, setSelectedRegion] = useState<string>("UK");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    let bucket = localConfig.aws_uk_s3_bucket;
    let region = localConfig.aws_uk_s3_bucket_region;

    if (selectedRegion === "US") {
      bucket = localConfig.aws_us_s3_bucket;
      region = localConfig.aws_us_s3_bucket_region;
    }
    if (selectedRegion === "EU") {
      bucket = localConfig.aws_eu_s3_bucket;
      region = localConfig.aws_eu_s3_bucket_region;
    }

    Storage.configure({
      region: region,
      bucket: bucket,
      identityPoolId: config.aws_user_pools_id,
      level: "protected",
    });
  }, [selectedRegion]);



  const handleFileUpload = async (event: { target: HTMLInputElement }) => {
    const element = event.target as HTMLInputElement;
    if (element.files) {
      //@ts-ignore
      const file = event.target.files[0];
      setIsLoading(true);
      Storage.put(file.name, file).then((response) => {
        enqueueSnackbar("File successfully uploaded!", { variant: "success" });
        element.value = "";
        setIsLoading(false);
      });
    }
  };

  return (
    <>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CirromStyles />
          <AppBar style={{ padding: "5px 0px" }}>
            <Toolbar variant="dense">
              <img alt="LogoSm" src="/cirrom.svg" style={{ height: "50px" }} />

              <Box style={{ flexGrow: 1 }} />
              <Button onClick={() => Auth.signOut()}>Logout</Button>
            </Toolbar>
          </AppBar>
          <div style={{ padding: "0 25%", marginTop: "100px", height: "100%" }}>
            <Card>
              <CardHeader
                title={
                  <Typography
                    variant={"h3"}
                    style={{ textAlign: "center", margin: "10px" }}
                  >
                    Cirrom File Upload Form
                  </Typography>
                }
              />
              <CardContent>
                <Divider style={{ marginBottom: "50px" }} />

                <div style={{ margin: "0px 50px" }}>
                  <Typography variant={"h5"} style={{ textAlign: "left" }}>
                    Upload your file on our secure
                    <Select
                      variant={"standard"}
                      value={selectedRegion}
                      onChange={(event) =>
                        setSelectedRegion(event.target.value)
                      }
                      style={{
                        marginLeft: "5px",
                        marginRight: "5px",
                        color: "#d41616",
                        fontWeight: "bold",
                      }}
                    >
                      <MenuItem value={"UK"}>UK</MenuItem>
                      <MenuItem value={"EU"}>EU</MenuItem>
                      <MenuItem value={"US"}>US</MenuItem>
                    </Select>
                    Cirrom servers
                  </Typography>
                  <Divider style={{ margin: "1rem 0rem" }} />
                  {isLoading && <LinearProgress variant="indeterminate" />}
                  <div
                    tabIndex={0}
                    onClick={(event) => {
                      if (inputWidget) {
                        //@ts-ignore
                        inputWidget.current.click();
                      }
                    }}
                    onDragOver={(event) => event.preventDefault()}
                    onDrop={(event) => {
                      //@ts-ignore
                      inputWidget.current.click();
                      event.preventDefault();
                    }}
                    style={{
                      height: "100%",
                      border: "1px dotted gray",
                      cursor: "pointer",
                    }}
                  >
                    <input
                      data-testid={"file_upload_input"}
                      //@ts-ignore
                      ref={inputWidget}
                      multiple
                      type="file"
                      autoComplete="off"
                      tabIndex={-1}
                      style={{ display: "none" }}
                      //@ts-ignore
                      onInputCapture={handleFileUpload}
                    />
                    <div
                      style={{
                        textAlign: "center",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                      }}
                    >
                      <img
                        alt="AddFiles"
                        src="/undraw_Add_files_re_v09g.svg"
                        style={{ height: "128px" }}
                      />
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
        </ThemeProvider>
      </StyledEngineProvider>
    </>
  );
}

export default withAuthenticator(App);
